import ImageNews1 from "../Assets/Images/News/image-news-1.png";
import ImageNews2 from "../Assets/Images/News/image-news-2.png";
import ImageNews3 from "../Assets/Images/News/image-news-3.png";
// import ImageNews4 from "../Assets/Images/News/image-news-4.png";
import ImageNews5 from "../Assets/Images/News/image-news-5.png";
// import ImageNews6 from "../Assets/Images/News/image-news-6.png";

import BgNews1 from "../Assets/Images/News/bg-news-1.png";
// import BgNews2 from "../Assets/Images/News/bg-news-2.png";
import BgNews3 from "../Assets/Images/News/bg-news-3.png";
// import BgNews4 from "../Assets/Images/News/bg-news-4.png";
import BgNews5 from "../Assets/Images/News/bg-news-5.png";
// import BgNews6 from "../Assets/Images/News/bg-news-6.png";

import Provider from "../Assets/Images/News/provider-logo.png";


export const newsData = [
    {
        id: 1,
        image: ImageNews1,
        title: 'Partnership with Fanzine',
        description: 'Pure Esports (Pure Sport Group Ltd) is delighted to announce several groundbreaking developments. Over the past six months, we have attracted significant interest from a diverse range of companies, drawn to our innovative approach to Esports. This interest underscores the recognition that Pure Esports is leading a transformative movement in the Esports sector, making our platform an appealing proposition.',
        externalLink: false,
        time: '2024-06-14T09:15:00Z',
        backgroundImage: BgNews1,
        provider: Provider,
        timeToRead: "4 min read",
        htmlContent: `
      <div>
        <p>Our discussions with these interested parties have focused on integrating new features into the Pure platform, promising a significantly enhanced product with minimal delays to our launch timeline. We are thrilled to announce a strategic partnership with Fanzine (www.fanzine.com), a leading fully automated sports content platform that aggregates comprehensive information, statistics, buzz, and other relevant content about all sports and teams. Fanzine's existing platform, with approximately 7.5 million users and growing by 20 thousand users per week, will be integrated into the Pure platform from day one. This integration will enable us to launch with nearly 8 million users already onboard.</p>
        <br/>
        <p>Fanzine will provide a comprehensive module allowing our users to access extensive Esports and traditional sports information through our platform. This collaboration will also offer invaluable insights into user behavior patterns, enabling us to optimize our services effectively. By creating our own competitive content in tournaments, leagues, and challenges, along with unique educational content from verified professionals, and leveraging Fanzine's established user base, we are building a comprehensive gaming ecosystem that includes all essential elements of Esports.</p>
<br/>
<p>As we move forward, Pure Esports remains committed to revolutionizing the Esports experience. </p>
<br/>
About Pure Esports (Pure Sport Group Ltd) 
<br/>
Pure Esports (Pure Sport Group Ltd) is an emerging leader in the Esports industry, dedicated to enhancing the Esports experience through innovative technology and strategic collaborations. Our Platform provides comprehensive information and insights into Esports and traditional sports, catering to a diverse and growing audience.
      <br/>
      <p>For media inquiries, please contact: </p>
      <br/>
      info@puresportgroup.com
      
      
      </div>
    `,
    },
    {
        id: 2,
        image: ImageNews2,
        title: 'Tokenomics white paper',
        description: 'Pure Sport Group will be the leading destination for all sports and esports. Pure is',
        externalLink:true,
        link:"https://pure-esports.gitbook.io/puresportgroup",
        time: '2024-06-14T09:15:00Z',
        backgroundImage: BgNews1,
        provider: Provider,
        timeToRead: "4 min read",
        htmlContent: `
      <div>
        <p>Our discussions with these interested parties have focused on integrating new features into the Pure platform, promising a significantly enhanced product with minimal delays to our launch timeline. We are thrilled to announce a strategic partnership with Fanzine (www.fanzine.com), a leading fully automated sports content platform that aggregates comprehensive information, statistics, buzz, and other relevant content about all sports and teams. Fanzine's existing platform, with approximately 7.5 million users and growing by 20 thousand users per week, will be integrated into the Pure platform from day one. This integration will enable us to launch with nearly 8 million users already onboard.</p>
        <br/>
        <p>Fanzine will provide a comprehensive module allowing our users to access extensive Esports and traditional sports information through our platform. This collaboration will also offer invaluable insights into user behavior patterns, enabling us to optimize our services effectively.
By creating our own competitive content in tournaments, leagues, and challenges, along with unique educational content from verified professionals, and leveraging Fanzine's established user base, we are building a comprehensive gaming ecosystem that includes all essential elements of Esports.</p>
<br/>
<p>As we move forward, Pure Esports remains committed to revolutionizing the Esports experience. Our IPO on the MAIN Stock Exchange marks a significant milestone in our journey, and we look forward to the exciting opportunities ahead.</p>
<br/>
About Pure Esports (Pure Sport Group Ltd) <br/>Pure Esports (Pure Sport Group Ltd) is an emerging leader in the Esports industry, dedicated to enhancing the Esports experience through innovative technology and strategic collaborations. Our Platform provides comprehensive information and insights into Esports and traditional sports, catering to a diverse and growing audience. The company is going public on the MAIN Stock Exchange in London in late July 2024.
      <br/>
      <p>For media inquiries, please contact: </p>
      <br/>
      info@puresportgroup.com
      
      
      </div>
    `,
    },
    {
        id: 3,
        image: ImageNews3,
        title: 'Pure Esports announces key developments and market expansion',
        description: 'Pure Esports, an innovative platform in the fast-growing international Esports market, is excited to announce several key developments:',
        externalLink: false,
        time: '2024-06-15T14:30:00Z',
        backgroundImage: BgNews3,
        provider: Provider,
        timeToRead: "5 min read",
        htmlContent: `
      <div>
        <h1>Community-Driven and Innovative</h1>
        <p>Pure Esports prides itself on being a community-driven company delivering top-tier Esports content. The platform focuses on three main areas: in-game activities, educational content, and Esports news.</p>
        <h1>In-Game Activities</h1>
        <p>Tournaments and Leagues: Pure Esports specializes in creating opportunities for gamers to participate in or host online tournaments and leagues. These events range from highly competitive tournaments with substantial prize pools to fun activities between friends, aiming to elevate the competitive gaming experience and reward players for their skills.</p>
        <h1>Educational Section</h1>
        <p>Academies and Masterclasses: Pure Esports offers educational content provided by professional players to ensure high-quality learning experiences. The platform emphasizes delivering the best value for the time players invest in their favourite games. Additionally, 1-2-1 coaching sessions with top-tier players are available for those seeking focused and intensive training.</p>
        <h1>News Section</h1>
        <p>Esports News: The platform provides a fully customizable news feed, allowing users to filter content by game, genre, pro-scene, individual players, and live tournaments. This hyper-personalization ensures gamers receive precisely the information they want, eliminating the need to sift through irrelevant content.</p>
<!--        <h1>What’s Next?</h1>-->
<!--        <ul>-->
<!--        <li>Token Launch (Mid-July): Pure Esports is preparing for a community-driven Web3 token launch in mid-July. More details will be announced shortly.</li>-->
<!--        <li>London Stock Exchange IPO (End-July): Currently listed on the Polish stock exchange, Pure Esports will transition to the “Main list” on the London Stock Exchange at the end of July. This move highlights the company's commitment to professionalism and growth.</li>-->
<!--        <li>Big Partnership Announcement (End-August/Early-September): Pure Esports will announce a major partnership with one of the world’s largest gaming companies shortly after the IPO. This partnership marks a significant milestone for the company.</li>-->
<!--        <li>Q3 Launch: The official launch of the Pure Esports platform is set for late Q3. The platform will feature regular software updates, real-time content, and new features, solidifying its role in the daily lives of global gamers.</li>-->
<!--        </ul>-->
<!--        <h1>Upcoming Features Post-Platform Launch</h1>-->
<!--        <ul>-->
<!--        <li>Cashback: The innovative Pure Esports card will offer 1-2% cashback on all purchases.</li>-->
<!--        <li>Banking: A real-time, low-cost global settlement solution, enabling users to pay and get paid in local currency instantly.</li>-->
<!--        <li>Deals: Access to the best deals and prices on top brands.</li>-->
<!--        <li>Greets: Personalized messages from gaming idols.</li>-->
<!--        <li>Fitness: Features to promote well-being, ensuring a balance between virtual and real life.</li>-->
<!--</ul>-->
<!--<p>Pure Esports aims to build a holistic ecosystem that addresses all aspects of an Esports enthusiast's life.</p>-->
      </div>
    `,
    },
    // {
    //     id: 4,
    //     image: ImageNews4,
    //     title: 'Pure esports announces delay in listing',
    //     description: 'As previously announced Pure Esport Group planned to make a listing of its esports business end July 2024. The listing must however be preceded by the ongoing launch of the Pure Token, which is in full progress with a positive market response.  The listing has therefore taken a halt until the Token launch is completed which will take about one more week. Thereafter, the listing process will proceed as planned with the expected listing shortly after the token launch.',
    //     externalLink: false,
    //     time: '2024-07-30T14:30:00Z',
    //     backgroundImage: BgNews4,
    //     provider: Provider,
    //     timeToRead: "1 min read",
    //     htmlContent: `
    //   <div>
    //    </div>
    // `,
    // },
    {
        id: 5,
        image: ImageNews5,
        title: 'The IOC embraces a new era with the first Olympic Esports Games set for Saudi Arabia in 2025.',
        description: 'The International Olympic Committee (IOC) made a historic decision today by establishing the Olympic Esports Games, with the inaugural event set to take place in Saudi Arabia in 2025. The proposal received unanimous support from the IOC members during the 142nd IOC Session in Paris. To organize the Games, the IOC has formed a 12-year partnership with the National Olympic Committee of Saudi Arabia.',
        externalLink: false,
        time: '2024-07-23T08:00:00Z',
        backgroundImage: BgNews5,
        provider: Provider,
        timeToRead: "5 min read",
        htmlContent: `
      <div>
      <p>
      IOC President Thomas Bach hailed the creation of the Olympic Esports Games as a significant step in keeping pace with the digital revolution, highlighting the enthusiastic engagement of the esports community and the appeal of the Olympic brand to young people. He emphasized the valuable experience that Saudi Arabia's National Olympic Committee (NOC) brings to the project, with the partnership firmly rooted in the Olympic Charter and values.
      <br/>
      <br/>
The IOC's journey with esports began in 2018 and culminated in the successful Olympic Esports Week in 2023. This success led to the commission of a study on the creation of the Olympic Esports Games, which ultimately resulted in a project that respects Olympic values while addressing the interests of the esports community, particularly in areas such as game selection, gender equality, and youth engagement.  HRH Prince Abdulaziz bin Turki Al Faisal emphasized the rich history and promising future of esports in Saudi Arabia, driven by the nation's youth and the ambitious Vision 2030 initiative. He expressed the commitment to providing a platform for new heroes and inspiring dreams, highlighting the opportunity to instill Olympic values in innovative ways.
      <br/>
      <br/>

HRH Princess Reema Bandar Al-Saud showcased the transformation of sport in Saudi Arabia, emphasizing the empowerment of women and youth. She highlighted the significant number of gamers in the nation, with a substantial female representation, and positioned the Olympic Esports Games as a natural step in opening up Saudi Arabia to the world.
      <br/>
      <br/>

Saudi Arabia's strong hosting credentials were underscored by its track record in organizing international esports events, attracting millions of fans both in person and online. The partnership with the IOC comes at a time of remarkable growth in sports in Saudi Arabia, with increased participation levels and a threefold increase in sports federations.
      <br/>
      <br/>

The nation's commitment to women's sports was particularly emphasized, with substantial growth in registered female athletes and national teams, as well as ensured female representation on sports federation boards.
      <br/>
      <br/>

Following the decision to host the Olympic Esports Games, work will commence on event specifics, including city selection, timing, game titles, qualification processes, and other details. The IOC will establish a dedicated structure within its organization to oversee the Games, adopting a distinct approach to financing and organization.
      <br/>
      <br/>

The IOC will prioritize collaboration with International Federations and National Olympic Committees already engaged in esports, highlighting a spirit of cooperation in developing this new chapter in Olympic history.
      
</p>
      </div>
    `,
    },
    // {
    //     id: 6,
    //     image: ImageNews6,
    //     title: 'Trafalgar Property Group enters into a loan agreement',
    //     description: 'Trafalgar (AIM:TRAF), the AIM quoted residential and assisted living property developer, announces it has today entered into a loan agreement with Ecap Esports Ltd ("Ecap Esports"). Ecap Esports has agreed to loan the Company the sum of £250,000, the proceeds of which will be ringfenced to cover costs associated with the recently announced proposed reverse takeover, should the transaction not occur. In the event the proposed transaction does not complete, any funds remaining following payment of all accrued transaction fees shall be returned to the lender. The loan bears no interest.',
    //     externalLink: false,
    //     time: '2024-06-03T09:15:00Z',
    //     backgroundImage: BgNews6,
    //     provider: Provider,
    //     timeToRead: "3 min read",
    //     htmlContent: `
    //   <div>
    //     <h6>Further to the announcement of 29 May 2024, shareholders should continue to note that the proposed acquisition is still under discussion and subject to a significant number of pre-conditions, including diligence, and there can be no certainty therefore at this time that the proposed acquisition will proceed as envisaged.</h6>
    //   </div>
    // `,
    // },
]